import { Outlet } from 'react-router-dom';
import SearchBar from './SearchBar';
import SearchLabel from './SearchLabel';
import SideNav from './SideNav';
import TopNav from './TopNav';
import BottomBar from './BottomBar';
import Banner from './Banner';

const PageLayout = ({
  setSideNav,
  handleSearch,
  searchQuery,
  setSearchQuery,
  isSubmitted,
  filteredItems,
  searchValue,
  close,
  sideNav,
  swiper,
  setModalOn,
}) => {
  return (
    <div className='hide-scrollbar'>
<TopNav setSideNav={setSideNav} />
<Banner />
<SearchBar
  handleSearch={handleSearch}
  searchQuery={searchQuery}
  setSearchQuery={setSearchQuery}
/>

      <div className='my-custom-pagination'></div>
      {isSubmitted && (
        <SearchLabel
          filteredItems={filteredItems}
          searchValue={searchValue}
          close={close}
        />
      )}
      <BottomBar setModalOn={setModalOn} />
      <SideNav sideNav={sideNav} setSideNav={setSideNav} swiper={swiper} />
      <Outlet />
    </div>
  );
};

export default PageLayout;
