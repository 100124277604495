import { BsX, BsSearch } from "react-icons/bs"

const SearchBar = ({  handleSearch,setSearchQuery, searchQuery}) => {
  return (
    <form
      className="px-4 pt-2 flex gap-2 relative dark:bg-gray-700"
      onSubmit={handleSearch}
    >
      <input
        onChange={(e)=>setSearchQuery(e.target.value)}
        value={searchQuery}
        autoComplete="off"
        type="text"
        enterKeyHint="search"
        placeholder="البحث . . ."
        name="search"
        className="outline-none border border-gray-200 focus:border-primaryGreen-500 transition duration py-1.5 px-4 pl-2 flex-grow rounded-full bg-transparent dark:placeholder:text-white dark:text-white peer"
      />
      {searchQuery.length>0 ? (
        <button
          className="w-10 h-10 flex items-center justify-center dark:text-gray-100 absolute left-4 dark:text-white text-gray-500 peer-focus:text-primaryGreen-500"
          onClick={()=>setSearchQuery('')}
          type='button'
          title="إنهاء البحث"
        >
          <BsX className="text-2xl" />
        </button>
      ) : (
        <button
          className="w-10 h-10 flex items-center justify-center rounded-md absolute left-4 peer-focus:text-primaryGreen-500 dark:text-white text-gray-500 focus:outline-none outline-none border-none transition-none active:outline-none"
          title="البحث"
        >
          <BsSearch className="" />
        </button>
      )}
    </form>
  )
}

export default SearchBar
