import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Banner.css';

const Banner = () => {
  // حالة التحكم في ظهور البنر
  const [isBannerVisible] = useState(false);

  const settings = {
    dots: false, // لإظهار أو إخفاء النقاط السفلية
    infinite: true, // للتكرار اللانهائي
    speed: 500, // سرعة التنقل بين الشرائح
    slidesToShow: 1, // عدد الشرائح المراد عرضها في وقت واحد
    slidesToScroll: 1, // عدد الشرائح المراد تمريرها في كل حركة
    autoplay: true, // للتشغيل التلقائي
    autoplaySpeed: 3000, // سرعة التشغيل التلقائي بالمللي ثانية
    swipe: true, // لتفعيل السحب باللمس
    touchThreshold: 10, // لضبط عتبة السحب باللمس (قيمة أعلى تجعل السحب أسهل)
  };

  if (!isBannerVisible) {
    return null; // عدم إرجاع أي شيء في حالة عدم ظهور البنر
  }

  return (
    <div className="banner-container">
      <Slider {...settings}>
        <div>
          <img src="" alt="Banner 4" tabIndex="-1" />
        </div>
        <div>
          <img src="" alt="Banner 5" tabIndex="-1" />
        </div>
      </Slider>
    </div>
  );
};

const App = () => {
  const [isBannerVisible] = useState(true);

  return (
    <div>
      {isBannerVisible && <Banner />}
    </div>
  );
};

export default App;
