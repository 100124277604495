import { useState, useEffect } from 'react';
import Modal from './Modal'; // افترض أن لديك مكون Modal بالفعل
import { FaPhoneVolume } from 'react-icons/fa'; // أيقونة رقم الجوال
import useDarkMode from '../hooks/useDarkMode'; // افترض أنك تستخدم وضعية الظلام
import '../index.css'; // افترض أنك تستخدم ملف CSS أساسي

function LoginModal({ setModalOn }) {
    useDarkMode();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);

    // قفل التمرير عند عرض أي نموذج
    useEffect(() => {
        if (phoneNumber || showSuccess) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto'; // إعادة الوضع الافتراضي عند إلغاء التثبيت
        };
    }, [phoneNumber, showSuccess]);

    function handleSubmit(e) {
        e.preventDefault();
        if (!phoneNumber) {
            setFormErrors({ phone: 'الرجاء إدخال رقم الجوال' });
            return;
        }

        // محاكاة إرسال البيانات بنجاح
        setShowSuccess(true);
        setTimeout(() => {
            setModalOn(false);
        }, 3000); // إغلاق النموذج بعد 3 ثوانٍ من النجاح
    }

    return (
        <Modal title='تسجيل الدخول' setModalOn={setModalOn}>
            <div className='modal-content dark:bg-gray-700'>
                {!showSuccess ? (
                    <form
                        className='fixed bottom-0 w-full flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems dark:bg-gray-700'
                        onSubmit={handleSubmit}
                    >
                        <div className='flex flex-col text-sm'>
                            <label htmlFor='phone' className='relative'>
                                <input
                                    type='tel'
                                    required
                                    id='phone'
                                    name='phone'
                                    autoComplete='off'
                                    pattern='[0-9]*'
                                    className={`outline-none peer border-b-2 focus:border-none dark:bg-gray-700 dark:text-white w-full h-10 mt-1 px-6 ${formErrors.phone ? 'border-red-500' : ''
                                        }`}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                                <FaPhoneVolume className='absolute right-0 top-[50%] translate-y-[-50%] text-gray-400 text-lg peer-focus:text-main-900' />
                                <div className='absolute bottom-0 bg-main-900 h-0.5 w-0 transition-all duration-500 peer-focus:w-full origin-center'></div>
                                <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-6 px-0.5 bg-white z-20 transition duration-200 dark:bg-gray-700 dark:text-gray-500'>
                                    رقم الجوال
                                </span>
                            </label>
                            {formErrors.phone && (
                                <span className='text-red-500 text-xs mt-1'>
                                    {formErrors.phone}
                                </span>
                            )}
                        </div>

                        <button
                            type='submit'
                            className={`bg-main-900 text-white py-2 w-full rounded-full ${!phoneNumber ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                        >
                            تسجيل الدخول
                        </button>
                    </form>
                ) : (
                    <div className="flex flex-col items-center justify-center text-main-900 dark:text-white">
                        <div>
                            {/* ضع هنا رمز النجاح أو أيقونة النجاح */}
                        </div>
                        <p className="mt-4 text-main-900 dark:text-white">
                            تم تسجيل الدخول بنجاح! شكراً لك.
                        </p>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default LoginModal;
