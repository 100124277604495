import { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Items from './components/Items';
import InstallPrompt from './components/InstallPrompt';
import PageLayout from './components/PageLayout';
import logo from './img/logo.png';
import bg from './img/bg.png';
import Delivery from './pages/Delivery';
import { data as categories } from './data';
import SingleItem from './components/SingleItem';
import CartPage from './components/CartPage';
import { ToastContainer } from 'react-toastify';
import useDarkMode from './hooks/useDarkMode';
import Options from './pages/Options';
import TableNumber from './components/TableNumber';
import ReciveFromStore from './components/ReciveFromStore';
import Transport from './components/Transport';
import SideNav from './components/SideNav';

function App() {
  const inputRef = useRef();

  const [sideNav, setSideNav] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [searchValue, setSearchValue] = useState(inputRef.current?.value || '');
  const [searchQuery, setSearchQuery] = useState('');
  const [swiper, setSwiper] = useState();
  const [quantity, setQuantity] = useState(1);

  const [singleItem, setSingleItem] = useState({});
  const [itemOn, setItemOn] = useState(false);
  const [cartOn, setCartOn] = useState(false);

  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    document.body.style.overflow = sideNav ? 'hidden' : 'visible';
  }, [sideNav]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target[0].value);

    if (searchQuery.length > 0) {
      setIsSubmitted(true);
      setSearchValue(e.target[0].value);
      const items = categories
        .flatMap((c) => c.items)
        .filter((i) => i.title.includes(e.target[0].value));

      setFilteredItems(items);
      setSearchQuery('');
      e.target[0].blur();
    }
    e.target[0].blur();
    document.documentElement.scrollTop = 0;
  };

  const close = () => {
    setIsSubmitted(false);
    setFilteredItems([]);
  };

  const pagination = {
    el: '.my-custom-pagination',
    clickable: true,
    renderBullet: (index, className) => {
      return `
        <span class="${className}">
          <span className="text-xs whitespace-nowrap font-semibold">
            ${categories[index]?.title}
          </span>
          <Image
            src=${categories[index]?.image}
            alt=""
            width="24"
            height="24"
            priority
            className='z-10'
          />
        </span>
      `;
    },
  };

  return (
    <>
      <Router>
        <div className="flex hide-scrollbar">
          <div
            className="relative h-auto max-w-md md:ml-auto md:mr-0 mx-auto transition duration-100 dark:bg-gray-700 hide-scrollbar w-full"
            dir="rtl"
          >
            <Routes>
              <Route
                element={
                  <PageLayout
                    setSideNav={setSideNav}
                    handleSearch={handleSearch}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    isSubmitted={isSubmitted}
                    filteredItems={filteredItems}
                    searchValue={searchValue}
                    close={close}
                    sideNav={sideNav}
                    swiper={swiper}
                    setModalOn={setCartOn}
                  />
                }
              >
                <Route
                  path="/"
                  element={
                    <Items
                      pagination={pagination}
                      close={close}
                      isSubmitted={isSubmitted}
                      filteredItems={filteredItems}
                      swiper={swiper}
                      setSwiper={setSwiper}
                      setModalOn={setItemOn}
                      modalOn={itemOn}
                      singleItem={singleItem}
                      setSingleItem={setSingleItem}
                      cartOn={cartOn}
                    />
                  }
                />
              </Route>
              <Route
                path="/"
                element={
                  <SideNav
                    sideNav={sideNav}
                    setSideNav={setSideNav}
                    swiper={swiper}
                  />
                }
              />
              <Route path="/delivery" element={<Delivery close={close} />} />
              <Route path="/options" element={<Options setCartOn={setCartOn} />} />
              <Route path="/in-restaurant" element={<TableNumber />} />
              <Route path="/recieve-from-store" element={<ReciveFromStore />} />
              <Route path="/transport" element={<Transport />} />
            </Routes>
          </div>
          <div
            className="hidden md:block h-screen w-[calc(100%-448px)] bg-no-repeat bg-cover fixed"
            style={{ backgroundImage: `url(${bg})` }}
          >
            <div className="bg-black/50 w-full h-full md:flex items-center justify-center">
              <div className="h-64 w-64 p-8 bg-white rounded-full">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
          {itemOn && singleItem?.title && (
            <SingleItem singleItem={singleItem} setModalOn={setItemOn} />
          )}
          {cartOn && (
            <CartPage
              singleItem={singleItem}
              setModalOn={setCartOn}
              quantity={quantity}
              setQuantity={setQuantity}
              setItemOn={setItemOn}
              setSingleItem={setSingleItem}
            />
          )}
        </div>
      </Router>
      <InstallPrompt />
      <ToastContainer
        rtl
        position="bottom-center"
        autoClose={2000}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        pauseOnHover
        draggable
        theme={isDarkMode ? 'dark' : 'light'}
      />
    </>
  );
}

export default App;
